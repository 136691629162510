import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,useDisclosure, Heading,Box,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";


import utils from "../../../utils/commonFunctions";
import Info from "./Info";
import ViewCustomerInfoModal from "./ViewCustomerInfoModal";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import {usePrepaid} from "../../../providers/PrepaidProvider";
import ViewTokenModal from "./ViewTokenModal";
import ResponseModal from "../../../components/PayGate/ResponseModal";
const BuyToken = () => {
    const {setTransactionDetails,transactionInfo}=usePrepaid()||{}
    const [loading, setLoading] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    //view customer info modal
    const {isOpen,onOpen,onClose}=useDisclosure()
    // Payment summary modal
    const { isOpen: isPaymentSummaryOpen, onOpen: onPaymentSummaryOpen, onClose: onPaymentSummaryClose } = useDisclosure();
    const [completed,setCompleted]=useState(false)
    useEffect(() => {
        if(completed){
            onOpen()
        }
    }, [completed]);
    // get query params from url
    const [reference, setReference] = useState("");
    const [statusCode, setStatusCode] = useState();
    const [reason, setReason] = useState();

    useEffect(() => {
        // get query params from url
        const urlParams = new URLSearchParams(window.location.search);
        setStatusCode(urlParams.get("code"));
        setReference(urlParams.get("reference"));
        setReason(urlParams.get("reason"));
    }, []);


    const customerMap = {
        meterNo: "Meter Number",
        phone:"Phone",
        email:"Email"
    }
    function checkCustomerIsValid(transactionInfo) {
        for (let key in transactionInfo) {
            if (transactionInfo[key] === null || transactionInfo[key] === undefined || transactionInfo[key] === "") {
                toast.closeAll();
                addGeneralToast({
                    message: `${customerMap[key]} is a required field`,
                    type: "warning",
                });
                setLoading(false);
                return false;
            }
        }
        return true
    }

    useEffect(() => {
        if(utils.isAuthenticated()){
            setTransactionDetails('phone',utils.getUser().phone)
            setTransactionDetails('email',utils.getUser().email)
            setTransactionDetails('customerId',utils.getUserId())
            setTransactionDetails('isLoggedIn',true)
        }
    }, []);
    const validateMeterNumber = async () => {
        try {
            setLoading(true);
            setCompleted(false);

            let { meterNo, phone, email } = transactionInfo;
            checkCustomerIsValid({ meterNo, phone, email });

            if (meterNo && phone && email) {
                const result = await utils.validateMeterNumber({ meterNo: meterNo });
                let status = result?.status;
                console.log('status: ', status)
                if (status === 'success') {
                    let data = result?.data;
                    // break it down into firstName, lastName
                    setTransactionDetails('fullName', data.customerName);
                    setTransactionDetails('vendor', data.vendor);
                    setTransactionDetails('canVend', data?.canVend);
                    setTransactionDetails('meterNo', data.meterNo);
                    setTransactionDetails('errorMessage', result?.message);
                    setTransactionDetails('status', true);
                } else {
                    setTransactionDetails('status', false);
                    setTransactionDetails('canVend', false);
                    setTransactionDetails('errorMessage', result?.message);
                }

                setCompleted(true);
            }
        } catch (error) {
            // Handle error case
            setTransactionDetails('status', false);
            setTransactionDetails('canVend', false);
            setTransactionDetails('errorMessage', error?.message || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box>
            <Stack spacing={4} justify="center" px={4} w="full" h="full">
                <Box textAlign={'start'} >
                    <Heading fontSize={'2xl'} color={'brand.500'}>Enter your meter number</Heading>
                    <Info text={'You can top up your meter credits anytime'}/>
                </Box>
                <FormControl  isRequired>
                    <FormLabel>Meter Number</FormLabel>
                    <Input
                        name={'meterNo'}
                        value={transactionInfo?.meterNo||""}
                        type="number"
                        onChange={(e) => setTransactionDetails("meterNo",e.target.value)}
                        disabled={loading}
                    />
                    {/*<Info text={"You'll receive a voucher code after a successful payment. Please enter the voucher code into your meter with the corresponding meter number to receive energy units."}/>*/}
                </FormControl>
                {!utils.isAuthenticated()&&
                    <>
                        <FormControl  isRequired>
                            <FormLabel>Phone</FormLabel>
                            <Input
                                name={'phone'}
                                value={transactionInfo?.phone||""}
                                type="number"
                                onChange={(e) => setTransactionDetails("phone",e.target.value)}
                                disabled={loading}
                            />
                        </FormControl>
                        <FormControl  isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name={'email'}
                                value={transactionInfo?.email||""}
                                type="email"
                                onChange={(e) => setTransactionDetails("email",e.target.value)}
                                disabled={loading}
                            />
                        </FormControl>
                    </>
                }
                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white"/>
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={validateMeterNumber}
                        >
                            Verify
                        </Button>

                    )}
                    {/*<Info text={'Kindly check your meter number before proceeding further to avoid any interruptions.'}/>*/}
                </Stack>

            </Stack>
                <ViewCustomerInfoModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onUpdatePaymentSummaryModal={onPaymentSummaryOpen}
                />
                <PaymentSummaryModal
                    isOpen={isPaymentSummaryOpen}
                    onClose={onPaymentSummaryClose}
                    balance={transactionInfo?.amount}
                    paymentType={'PrepaidPayment'}
                    customer={transactionInfo}
                />

            <ViewTokenModal statusCode={statusCode} reference={reference}/>
            {statusCode!=='200'&&
                <ResponseModal statusCode={statusCode} reference={reference} reason={reason}/>
            }

        </Box>
    );
};

export default BuyToken;
