import React, { createContext, useState, useContext } from 'react';

// Create the context
const PrepaidContext = createContext();

// Create a provider component
export const PrepaidProvider = ({ children }) => {
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dpoResponse, setDpoResponse] = useState(null);
    const [transactionInfo, setTransactionInfo] = useState({
        customerId: null,
        firstName: null,
        lastName:null,
        email: null,
        phone: null,
        meterNo: null,
        vendor: null,
        amount: null,
        errorMessage:null,
        paymentType:null,
        status: false,
        canVend:true
    });
    const resetTransactionDetails = () => {
        setTransactionInfo({
            customerId: null,
            fullName: null,
            email: null,
            phone: null,
            meterNo: null,
            vendor: null,
            amount: null,
            errorMessage:null,
            paymentType:null,
            status: false,
            canVend:true
        });
    }
    const setTransactionDetails = (key, value) => {
        console.log(key,value)
        setTransactionInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
    }


    return (
        <PrepaidContext.Provider value={{
            setTransactionDetails,
            transactionInfo,
            dpoResponse,
            setDpoResponse,
            refreshFlag,
            setRefreshFlag,
            resetTransactionDetails
        }}>
            {children}
        </PrepaidContext.Provider>
    );
};

// Custom hook to access the context
export const usePrepaid = () => useContext(PrepaidContext);
