import React, { Component } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import bgImage from '../../assets/BlueLogoNoBG.png';
import logoImage from '../../assets/BlueLogoNoBG.png';
import { MdDownload } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import moment from "moment";
import { API } from '../../utils/api';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        position: 'relative',
        fontFamily: 'Helvetica',
        fontSize: 11,
        padding: 40,
    },
    logo: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 30,
        left: '60%',
        marginLeft: -40,
    },
    background: {
        position: 'absolute',
        top: '30%',
        left: '15%',
        transform: 'translate(-30%, 0%)',
        opacity: 0.1,
        width: '100%',
    },
    header: {
        marginTop: 70,
        marginBottom: 30,
        fontSize: 20,
        textAlign: 'center',
        color: "#2BBCF2",
        fontWeight: 'bold',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 15,
    },
    col: {
        width: '50%',
        marginBottom: 15,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    label: {
        fontWeight: 'bold',
        marginRight: 8,
    },
    address: {
        fontWeight: 'bold',
        marginRight: 8,
        color: "#2BBCF2",
    },
    fieldValue: {
        flex: 1,
        textAlign: 'justify',
    },
});

const Grid = ({ children }) => <View style={styles.grid}>{children}</View>;
const Col = ({ children }) => <View style={styles.col}>{children}</View>;
const Row = ({ children }) => <View style={styles.row}>{children}</View>;

class PrepaidVocherPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastExecutionTime: 0,
        };
    }

   /* componentDidMount() {
        this.executeFunction();
    }*/

    generatePdfBlob = async () => {
        const { pdfData } = this.props; // Data to be displayed on the PDF
        const blob = await pdf(
            <Document>
                <Page size='A4' style={styles.page}>
                    <Image style={styles.background} src={bgImage} />
                    <Image style={styles.logo} src={logoImage} />
                    <Text style={styles.header}>PREPAID METER VOUCHER</Text>
                    <Grid>
                        <Col>
                            <Text style={styles.address}>Water Utilities Corporation</Text>
                            <Text style={styles.address}>Sedibeng House</Text>
                            <Text style={styles.address}>P/Bag 00276</Text>
                            <Text style={styles.address}>Gaborone</Text>
                        </Col>
                    </Grid>
                    <Grid>
                        <Col>
                            <Row>
                                <Text style={styles.label}>Customer Details</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Customer Name :</Text>
                                <Text style={styles.value}>{pdfData?.fullName}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Meter No :</Text>
                                <Text style={styles.value}>{pdfData?.meterNo}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Cost (Pula) :</Text>
                                <Text style={styles.value}>{pdfData?.amount}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Units (Kl) :</Text>
                                <Text style={styles.value}>{pdfData?.units}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Date and Time:</Text>
                                <Text style={styles.value}>{moment(pdfData?.updatedDate).format('DD-MM-YYYY, HH:mm:ss')}</Text>
                            </Row>

                            <Row>
                                <Text style={styles.label}>Voucher Token:</Text>
                                <Text style={styles.value}>{pdfData?.token}</Text>
                            </Row>
                        </Col>
                    </Grid>
                </Page>
            </Document>
        ).toBlob();

        return blob;
    };

   /* executeFunction = async () => {
        const { lastExecutionTime } = this.state;
        if (Date.now() - lastExecutionTime > 60000) {
            await this.sendNotification(await this.generatePdfBlob());
            this.setState({ lastExecutionTime: Date.now() });
        }
    };*/

    generatePOP = async (blob) => {
        try {
            // Upload the PDF
            const formData = new FormData();
            formData.append('file', blob, 'wuc_prepaid_meter_voucher.pdf');
            formData.append('folderPath', 'PrepaidMeterVoucher');

            const response = await API.post(`${process.env.REACT_APP_API_BASE_URL}/common/file/upload`, formData);

            console.log('PDF uploaded successfully', response.data.data.fileUrl);
            return response.data.data.fileUrl;
        } catch (error) {
            console.error('Error uploading PDF', error);
            return null;
        }
    };

    sendNotification = async (blob) => {
        try {
            const attachments = [{
                filename: 'WUC Prepaid Meter Voucher',
                path: await this.generatePOP(blob),
                contentType: 'application/pdf'
            }];
            const { pdfData } = this.props;

            const requestBody = {
                type: "Email",
                recipient: pdfData?.email,
                subject: "WUC Prepaid Voucher Token",
                message: `<p>You have successfully purchased prepaid voucher token.</p><p>Find attached prepaid voucher.</p>`,
                attachments: attachments
            };

            const response1 = await API.post(`${process.env.REACT_APP_API_BASE_URL}/common/notification/send`, JSON.stringify(requestBody));
            console.log("send notification response: ", response1);
            return response1.data;

        } catch (error) {
            console.error('Error sending notification', error);
        }
    };
    downloadAndUploadPdf = async () => {
        try {
            const blob = await this.generatePdfBlob();

            // Download the PDF
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'wuc_prepaid_meter_voucher.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            //await this.sendNotification(blob);

        } catch (error) {
            console.error('Error uploading PDF', error);
        }
    };

    render() {
        return (
            <Button rightIcon={<MdDownload />} bg='red.400' _hover={{ bg: 'red.500' }} variant='solid' onClick={this.downloadAndUploadPdf}>
                Download Receipt
            </Button>
        );
    }
}

export default PrepaidVocherPdf;
