import {
    Flex,
    Input,
    Image,
    Button,
    Heading,
    FormLabel,
    FormControl,
    HStack,
    InputGroup,
    Card,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody, Text, SimpleGrid, Stack,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import WUCLogo from "../../../assets/BlueLogoNoBG.png";
import {FormatMoney} from "format-money-js";
import Info from "./Info";
import {usePrepaid} from "../../../providers/PrepaidProvider";

const QuickRechargeButton = ({value, callBack}) => {
    return (
        <Button bg={'gray.50'} color={'blue.800'} onClick={() => callBack(value)}>
            {value}
        </Button>
    );
}

const ViewBalanceModal = ({isOpen, onClose, onUpdatePaymentSummaryModal}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [dangerColor, setDangerColor] = useState("gray.500");
    const {setTransactionDetails, resetTransactionDetails, transactionInfo} = usePrepaid() || {}
    const fm = new FormatMoney({decimals: 2});
    useEffect(() => {
        setShow(false);
        setLoading(false);
    }, []);
    useEffect(() => {
        if (inputValue !== "") {
            setTransactionDetails('amount', inputValue)
        } else {
            setTransactionDetails('amount', "")
        }
    }, [inputValue]);
    const handlePayAmount = (value) => {
        if (value < 20) {
            setDangerColor('red.500')
        } else {
            setDangerColor('gray.500')
        }
        setInputValue(value);
    };
    const handlePayment = () => {
        onClose();
       // handlePayAmount("")
        onUpdatePaymentSummaryModal();
    };
    const handleClose = () => {
        onClose();
        handlePayAmount("")
        resetTransactionDetails()
    }

    return (
        <Modal minW="lg" zIndex="10000" closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalBody>
                    <Flex p={4} textAlign="center" color="blue.800" alignItems="center" justifyContent="center">
                        <Card w="full" variant="unstyled">
                            <HStack w="full" justifyContent="center">
                                <Image
                                    alt="ResetPassword Image"
                                    src={WUCLogo}
                                    objectFit="contain"
                                    h={120}
                                    w={120}
                                />
                            </HStack>
                            {!loading && (

                                <>

                                    <>
                                        {transactionInfo?.meterNo &&
                                            <Flex direction={'column'} align={'start'} justifyContent={'start'}
                                                  bg={'blue.50'}
                                                  borderRadius={'2xl'} p={4} mt={4}>
                                                <Heading fontSize={'sm'} mb={2}>Customer Details</Heading>
                                                {transactionInfo?.fullName &&
                                                    <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                          color={'gray.700'} textTransform={"capitalize"}>
                                                        <Text fontWeight={'medium'}>Customer Name:</Text>
                                                        <Text fontWeight={'semibold'}>{transactionInfo?.fullName}</Text>
                                                    </Flex>
                                                }
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Meter No:</Text>
                                                    <Text> {transactionInfo?.meterNo}</Text>
                                                </Flex>
                                                <Flex mt={2} justify={'start'} w={'full'} color={'red.500'}>
                                                    <Info
                                                        text={'Please verify your customer details before you proceed.'}/>
                                                </Flex>
                                            </Flex>
                                        }
                                        {transactionInfo.canVend &&

                                            <>


                                                <FormControl textAlign={'start'} id="amountPaid" mt={4}>
                                                    <FormLabel color="blue.800">Recharge amount</FormLabel>
                                                    <InputGroup>
                                                        <Input
                                                            type="number"
                                                            value={transactionInfo?.amount}
                                                            onChange={(e) => handlePayAmount(e.target.value)}
                                                            isDisabled={!!loading}
                                                        />
                                                    </InputGroup>
                                                    <Info color={dangerColor} text={'minimum recharge of 20 pula'}/>
                                                </FormControl>
                                                <Text color={'brand.500'} textAlign={'start'} my={{base: 4}}>Quick
                                                    recharge
                                                    (BWP)</Text>
                                                <SimpleGrid columns={{base: 3}} gap={{base: 2}}>
                                                    <QuickRechargeButton value={20} callBack={handlePayAmount}/>
                                                    <QuickRechargeButton value={50} callBack={handlePayAmount}/>
                                                    <QuickRechargeButton value={100} callBack={handlePayAmount}/>
                                                    <QuickRechargeButton value={200} callBack={handlePayAmount}/>
                                                    <QuickRechargeButton value={300} callBack={handlePayAmount}/>
                                                    <QuickRechargeButton value={500} callBack={handlePayAmount}/>
                                                </SimpleGrid>
                                                <Button
                                                    type="submit"
                                                    variant="solid"
                                                    mt={4}
                                                    cursor={(inputValue === "" || inputValue < 20) ? "not-allowed" : "pointer"}
                                                    isDisabled={inputValue === "" || inputValue < 20}
                                                    onClick={handlePayment}
                                                >
                                                    Proceed
                                                </Button>
                                            </>
                                        }
                                    </>


                                    {!transactionInfo?.canVend &&
                                        <Stack width={'full'} mt={8} justify={'space-between'} fontSize={'sm'}
                                               color={'red.500'}>
                                            <Text fontSize={'xl'} fontWeight={'semibold'}>
                                                {transactionInfo?.errorMessage}
                                            </Text>
                                            <Text fontSize={'xl'} fontWeight={'normal'}>
                                                Please try again or contact system administrator for further
                                                assistance
                                            </Text>
                                        </Stack>
                                    }
                                </>
                            )}

                            <Button
                                type="submit"
                                variant="ghost"
                                mt={4}
                                cursor={!loading ? "pointer" : "not-allowed"}
                                isDisabled={loading}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Card>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ViewBalanceModal;
