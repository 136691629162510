import React, {useEffect, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Image, pdf} from '@react-pdf/renderer';
import bgImage from '../../assets/BlueLogoNoBG.png';
import logoImage from '../../assets/BlueLogoNoBG.png';
import {MdDownload} from "react-icons/md";
import {Button} from "@chakra-ui/react";
import moment from "moment";
import utils from "../../utils/commonFunctions";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        position: 'relative',
        fontFamily: 'Helvetica',
        fontSize: 11,
        padding: 40,
    },
    logo: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 30,
        left: '60%',
        marginLeft: -40,
    },
    background: {
        position: 'absolute',
        top: '30%',
        left: '15%',
        transform: 'translate(-30%, 0%)',
        opacity: 0.1,
        width: '100%',
    },
    header: {
        marginTop: 70,
        marginBottom: 30,
        fontSize: 20,
        textAlign: 'center',
        color: "#2BBCF2",
        fontWeight: 'bold',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 15,
    },
    col: {
        width: '50%',
        marginBottom: 15,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    label: {
        fontWeight: 'bold',
        marginRight: 8,
    },
    address: {
        fontWeight: 'bold',
        marginRight: 8,
        color: "#2BBCF2",
    },
    fieldValue: {
        flex: 1,
        textAlign: 'justify',
    },
});

const Grid = ({children}) => <View style={styles.grid}>{children}</View>;
const Col = ({children}) => <View style={styles.col}>{children}</View>;
const Row = ({children}) => <View style={styles.row}>{children}</View>;

const PaymentReceiptPdf = ({pdfData}) => {
    let [receiptBlob, setReceiptBlob] = useState()
    const generatePdfBlob = async () => {
        const blob = await pdf(
            <Document>
                <Page size='A4' style={styles.page}>
                    <Image style={styles.background} src={bgImage}/>
                    <Image style={styles.logo} src={logoImage}/>
                    <Text style={styles.header}>ONLINE PAYMENT RECEIPT</Text>
                    <Grid>
                        <Col>
                            <Text style={styles.address}>Water Utilities Corporation</Text>
                            <Text style={styles.address}>Sedibeng House</Text>
                            <Text style={styles.address}>P/Bag 00276</Text>
                            <Text style={styles.address}>Gaborone</Text>
                        </Col>
                    </Grid>
                    <Grid>
                        <Col>
                            <Row>
                                <Text style={styles.label}>Reference :</Text>
                                <Text style={styles.value}>{pdfData?.reference}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Payment Date :</Text>
                                <Text
                                    style={styles.value}>{moment(pdfData?.editedDate).format('DD-MM-YYYY, HH:mm:ss')}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Receipt Issued By :</Text>
                                <Text style={styles.value}>WUC Corporate Website</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Customer Name :</Text>
                                <Text style={styles.value}>{pdfData?.fullName}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Amount Paid (Pula) :</Text>
                                <Text style={styles.value}>{pdfData?.amount}</Text>
                            </Row>
                        </Col>
                    </Grid>
                </Page>
            </Document>
        ).toBlob();

        return blob;
    };

    useEffect(() => {
        async function getBlob() {
            if (pdfData) {
                let blob =await generatePdfBlob()
                if (blob) {
                    setReceiptBlob(blob)
                } else {
                    setReceiptBlob(null)
                }
            }
        }
       getBlob()
    }, [pdfData]);
    useEffect(() => {
        async function sendReceiptNotification() {
            await utils.sendPaymentReceipt(receiptBlob,pdfData)
        }
        if (receiptBlob) {
           //upload receipt
            //this is causing errors
            //sendReceiptNotification()
        }
    }, [receiptBlob]);
    const downloadAndUploadPdf = async () => {
        try {
            if (pdfData){
                const blob = await generatePdfBlob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'payment_receipt.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

        } catch (error) {
            console.error('Error uploading PDF', error);
        }
    };

    return (
        <>
           {receiptBlob &&
                <Button rightIcon={<MdDownload/>} bg='green.400' _hover={{bg: 'green.500'}} variant='solid'
                        onClick={()=>downloadAndUploadPdf()}>
                    Download Receipt
                </Button>
            }
        </>

    );
};

export default PaymentReceiptPdf;
